import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="h-screen max-w-4xl mx-auto flex flex-col justify-center items-center text-white text-center">
      <h1 className="text-3xl md:text-5xl font-semibold text-white">
        404 Not Found
      </h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link
        to="/"
        className="inline-block md:block text-center text-white capitalize rounded-full text-lg md:text-2xl focus:outline-none py-4 px-8 my-8 lg:w-5/12 bg-orange siteFont hover:bg-darkOrange transition duration-300 ease-in-out"
      >
        Go to homepage
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
